import React, { useContext, createContext, useState } from 'react';
import PropTypes from 'prop-types';
import useProvideDialog from './_useProvideDialog';
import { Alert } from '../../components/dialog';
import { useSelector } from 'react-redux';
import { getters } from '../../redux/store';

const dialogContext = createContext();

export const ProvideDialog = ({ children }) => {
  const [_globalAlertParamter, _setGlobalAlertParamter] = useState({
    show: false,
    showIcon: true,
    title: undefined,
    icon: undefined,
    txtLeft: undefined,
    txtRight: undefined,
    onLeft: undefined,
    onRight: undefined,
    option: undefined,
  });
  const dialog = useProvideDialog(_setGlobalAlertParamter);

  return (
    <dialogContext.Provider value={dialog}>
      <Alert
        show={_globalAlertParamter.show}
        showIcon={_globalAlertParamter.showIcon}
        title={_globalAlertParamter.title}
        icon={_globalAlertParamter.icon}
        txtLeft={_globalAlertParamter.txtLeft}
        txtRight={_globalAlertParamter.txtRight}
        onLeft={_globalAlertParamter.onLeft}
        onRight={_globalAlertParamter.onRight}
        option={_globalAlertParamter.option}
      />
      {children}
    </dialogContext.Provider>
  );
};

ProvideDialog.propTypes = {
  children: PropTypes.node,
};

export const useDialog = () => {
  return useContext(dialogContext);
};
