import { createSlice } from '@reduxjs/toolkit';

export const currentUseCoupon = createSlice({
  name: 'currentUseCoupon',
  initialState: {
    data: '',
  },

  reducers: {
    setCurrentUseCoupon: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setCurrentUseCoupon } = currentUseCoupon.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.isLoading`
export const getCurrentUseCoupon = (state) => state.currentUseCoupon.data;

export default currentUseCoupon.reducer;
