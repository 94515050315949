import React from 'react';

const callAppFunc = (fnName, params = {}) => {
  const jsonStr = {
    fnName: fnName,
    params: params,
  };
  if (typeof window !== 'undefined' && window.callAppFn) {
    // eslint-disable-next-line no-undef
    callAppFn(JSON.stringify(jsonStr));
  }
};

export { callAppFunc };

const WebFn = ({ callback }) => {
  // console.log('data', data);
  if (typeof window !== 'undefined') {
    window.callWebFn = (jsonStr) => {
      callback(jsonStr);
    };
  }
};

export default WebFn;
