import React from 'react';

import IconSuccess from '../../images/icons/icon-success/icon-success.png';
import Button from '../button';
import DialogBase from './_alert_base';

const Alert = ({
  show,
  showIcon,
  title,
  icon,
  txtLeft,
  txtRight,
  onLeft,
  onRight,
  option,
}) => {
  return (
    <>
      <DialogBase show={show}>
        <div className="alert-title" style={option?.title ?? {}}>
          {title || ''}
        </div>
        {showIcon && <img className="alert-icon" src={icon || IconSuccess} />}
        <div className="alert-buttons">
          {txtLeft !== undefined && txtLeft && (
            <Button
              gray
              onClick={onLeft}
              text={txtLeft || '取消'}
              style={option?.left ?? {}}
            />
          )}
          {txtRight !== undefined && txtRight && (
            <Button
              onClick={onRight}
              text={txtRight || '確定'}
              style={option?.right ?? {}}
            />
          )}
        </div>
      </DialogBase>

      <style jsx>{`
        .alert-title {
          font-family: Noto Sans TC;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 35px;
          color: #3b3516;
          margin-bottom: 14px;
          white-space: break-spaces;
        }

        .alert-icon {
          margin-bottom: 12px;
        }

        .alert-buttons {
          display: flex;
          // grid-template-columns: 1fr 1fr;
          column-gap: 12px;
        }
      `}</style>
    </>
  );
};

export default Alert;
